<template>
  <div class="d-flex flex-column justify-center">
    <v-text-field v-model="searchNeedle" placeholder="Rechercher ..." prepend-icon="mdi-magnify"
                  class="mr-12"></v-text-field>
    <div class="d-flex flex-row align-center justify-space-between">
      <v-btn color="primary" @click="selectMultiple('all')">Toutes</v-btn>
      <v-btn color="primary" @click="selectMultiple('containing', 'preprod')">Preprods</v-btn>
      <v-btn color="primary" @click="selectMultiple('not-containing', 'preprod')">Prods</v-btn>
    </div>
    <v-card flat height="420px" class="mt-2">
      <vue-scroll>
        <v-tree
            :list="clientListWithSearch"
            show-total
            show-subtotal
            @add="addClient"
            @remove="removeClient"
            @toggle-expand="toggleExpand"
            :loading="loadingClientList"
        ></v-tree>
      </vue-scroll>
    </v-card>
  </div>
</template>

<script>
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "ClientList",
  props: {
    value: {
      type: [Array, Object],
      default: () => []
    }
  },
  computed: {
    clientListWithSearch() {
      let list = JSON.parse(JSON.stringify(this.clientList))

      let selectedItems = this.value.map(client => client.name)
      let searchFiltered = list.filter(client => {
        let clientMatch = !!client.name.match(this.searchNoAccents(this.searchNeedle)) > 0

        client.bases = client.bases.filter(base => {
          let matchResult = !!base.match(this.searchNoAccents(this.searchNeedle))
          return clientMatch || matchResult
        })
        client.children = client.children.filter(child => {
          child.selected = selectedItems.indexOf(child.name) !== -1
          let matchResult = !!child.name.match(this.searchNoAccents(this.searchNeedle))
          return clientMatch || matchResult
        })

        return (client.bases.length > 0)

      })

      return searchFiltered
    }
  },
  data() {
    return {
      loadingClientList: false,
      clientList: [],
      selectedItems: [],
      searchNeedle: ""
    }
  },
  methods: {
    toggleExpand(index) {
      let realIndex = this.clientList.findIndex(client => client.name == this.clientListWithSearch[index].name)
      if (this.clientList[realIndex].expanded === undefined)
        this.$set(this.clientList[realIndex], "expanded", true)
      else {
        this.clientList[realIndex].expanded = !this.clientList[realIndex].expanded
      }
    },
    addClient(items) {
      let compare = this.selectedItems.map(a => a.name)
      for (let item of items) {
        if (compare.indexOf(item.name) === -1) {
          this.selectedItems.push(item)
        }
      }
      this.$emit('input', this.selectedItems)
    },
    removeClient(items) {
      for (let item of items) {
        let index = this.selectedItems.findIndex(a => a.name == item.name)
        if (index !== -1) {
          this.selectedItems.splice(index, 1)
        }
      }
      this.$emit('input', this.selectedItems)
    },
    selectAll() {
      this.selectedItems = []
      this.clientList.forEach(parent => {
        parent.selected = true
        parent.children.forEach(child => {
          child.selected = true
          this.selectedItems.push(child)
        })
      })
    },
    unselectAll() {
      this.clientList.forEach(parent => {
        parent.selected = false
        parent.children.forEach(child => {
          child.selected = false
        })
      })
      this.selectedItems = []
    },
    selectContaining(str, exclude = false) {
      this.clientList.forEach(parent => {
        parent.children.forEach(child => {
          if (child.name.includes(str) != exclude) {
            child.selected = true
            this.selectedItems.push(child)
          }
          parent.selected = parent.children.filter(child => child.selected).length == parent.children.length
        })
      })
    },
    selectMultiple(which, search) {
      if (which == 'all') {
        let allChecked = this.clientList.filter(parent => parent.selected).length == this.clientList.length
        if (!allChecked)
          this.selectAll()
        else
          this.unselectAll()
      } else {
        this.unselectAll()
        if (which == "containing") {
          this.selectContaining(search)
        } else if (which == 'not-containing') {
          this.selectContaining(search, true)
        }
      }

      this.$emit('input', this.selectedItems)
    }
  },
  async mounted() {
    this.loadingClientList = true
    let bases = await PlaneteOnlineService.getBases(true)
    this.clientList = bases
    this.loadingClientList = false
    this.selectedItems = this.value
  }
}
</script>

<style scoped>

</style>